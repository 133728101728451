@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Hanson";
  src: url("./assets/font/Hanson-Bold.ttf");
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-grotesk {
  font-family: "Space Grotesk", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-mono {
  font-family: "Space Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-hanson {
  font-family: "Hanson";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.react-datetimerange-picker__wrapper {
  border: 0 !important;
  padding: 0.5rem !important;
}

.react-datetimerange-picker__range-divider {
  padding: 0 0.25rem 0 0.25rem !important;
  text-transform: uppercase !important;
  letter-spacing: 0.025em !important;
  font-weight: 700 !important;
  font-size: 0.75rem;
  line-height: 1rem;
}

@layer components {
  .v2 h1 {
    @apply mt-12 lg:mt-16 text-hanson text-3xl lg:text-6xl uppercase font-normal lg:font-bold
  }

  .v2 h2 {
    @apply mt-5 lg:mt-12 text-grotesk text-2xl lg:text-2.5xl uppercase font-bold  
  }

  .v2 h3 {
    @apply mt-5 lg:mt-12 text-grotesk text-xl lg:text-3xl uppercase font-normal
  }
  
  .v2 .lead {
    @apply mt-4 lg:mt-8 text-mono font-normal text-lg lg:text-2xl
  }

  .v2 .paragraph {
    @apply mt-4 lg:mt-8 text-grotesk font-normal text-base lg:text-xl
  }

  .v2 section {
    @apply w-full flex flex-col  
  }

  .v2 .form {
    max-width: 800px;
  }

}


@layer utilities {
  @variants responsive {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}


@media (min-width: 640px) {
  .react-datetimerange-picker__wrapper {
    border: 0 !important;
    justify-items: center !important;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
  }

  .react-datetimerange-picker__range-divider {
    text-transform: uppercase !important;
    letter-spacing: 0.025em !important;
    font-weight: 700 !important;
    font-size: 1rem;
    line-height: 1rem;
  }
}

/* QUILL CUSTOM CSS */
/* .ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;
    ::before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font {
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
} */

.ql-picker.ql-font .ql-picker-label[data-value="Grotesk"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Grotesk"]::before {
  font-family: "Space Grotesk", sans-serif;
  content: "Grotesk" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Mono"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Mono"]::before {
  font-family: "Space Mono", monospace;
  content: "Mono" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Hanson"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Hanson"]::before {
  font-family: "Hanson";
  content: "Hanson" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before {
  font-family: "Roboto", sans-serif;
  content: "Roboto" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before {
  font-family: "Raleway";
  content: "Raleway" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before {
  font-family: "Montserrat";
  content: "Montserrat" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before {
  font-family: "Lato";
  content: "Lato" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Rubik"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Rubik"]::before {
  font-family: "Rubik";
  content: "Rubik" !important;
}

/* Set content font-families */
.Grotesk {
  font-family: "Space Grotesk", sans-serif;
}
.Mono {
  font-family: "Space Mono", monospace;
}
.Hanson {
  font-family: "Hanson";
}

.Roboto {
  font-family: "Roboto";
}
.Raleway {
  font-family: "Raleway";
}
.Montserrat {
  font-family: "Montserrat";
}
.Lato {
  font-family: "Lato";
}
.Rubik {
  font-family: "Rubik";
}
