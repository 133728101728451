.search {
   position: absolute;
   top: 1.5 rem;
   right: 1.5rem;
   left: 85%;
   transform: translateX(-220%);
   width: 100%;
   max-width: 350px;
   z-index: 10;
 }
 .search input {
   padding: 1rem;
   font-size: 1.5rem;
   width: 90%;
   border: 1.2px solid #f64f4f;
   border-radius: 1px;
   box-shadow: 1px 1px 1px 1px rgba(0, 0, 0);
   margin-top: 1rem;
 }